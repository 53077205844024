module.exports = {
  model: {
    id: '',
    number: null,
    date: new Date(),
    note_type_id: null,
    value: null,
  },
  required: ['number', 'date', 'note_type_id', 'value']
}
