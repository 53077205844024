<template>
  <div class="invoices">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova nota fiscal</span>
    </button>
    <b-table
      :data="allInvoices"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="
        (row) => $buefy.toast.open(`Visualizando os detalhes de ${row.number}`)
      "
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="number"
        label="Número"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.number }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.number }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="date"
        label="Data"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ $moment(props.row.date).format('DD/MM/YYYY') }}</b-table-column
      >

      <b-table-column
        field="note_type"
        label="Tipo de nota"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.note_type.name }}</b-table-column
      >

      <b-table-column
        field="value"
        label="Valor"
        :searchable="true"
        sortable
        v-slot="props"
        >R$ {{ parseFloat(props.row.value).toFixed(2) }}</b-table-column
      >

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.number }}</strong>
                <br />
                <strong>Data:</strong>
                <small>{{
                  $moment(props.row.date).format('DD/MM/YYYY')
                }}</small>
                <br />
                <strong>Tipo de nota:</strong>
                <small>{{ props.row.note_type.name }}</small>
                <br />
                <strong>Valor:</strong>
                <small>R$ {{ parseFloat(props.row.value).toFixed(2) }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Número *">
              <b-input required v-model="model.number"></b-input>
            </b-field>
            <b-field label="Data *">
              <b-datepicker
                v-model="model.date"
                required
                inline
                :unselectable-days-of-week="[0, 6]"
              ></b-datepicker>
            </b-field>
            <b-field label="Tipo *">
              <b-select
                expanded
                placeholder="Selecione o tipo"
                required
                v-model="model.note_type_id"
              >
                <option
                  v-for="(item, index) in allNoteTypes"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.value"
              ></b-input>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/invoices/store/service'
import Invoice from '../models/invoice'

export default {
  name: 'Invoices',
  data() {
    return {
      model: Invoice.model,
      showDetailIcon: false,
      isCardModalActive: false,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('noteTypes', ['allNoteTypes']),
    ...mapState('invoices', ['allInvoices']),
    ...mapState('maintenances', ['allMaintenances']),
  },
  methods: {
    ...mapActions('noteTypes', ['getAllNoteTypes']),
    ...mapActions('invoices', ['getAllInvoices']),
    ...mapActions('maintenances', ['getAllMaintenances']),
    async init() {
      await this.getAllInvoices()
      await this.getAllNoteTypes()
      await this.getAllMaintenances()
    },
    onEdit(item) {
      item.value = parseFloat(item.value).toFixed(2)
      item.date = new Date(this.$moment(item.date))

      this.model = { ...item }
    },
    async validation() {
      let isValid = true

      await Invoice.required.forEach((x) => {
        if (!this.model[x]) {
          isValid = false
        }
      })

      return isValid
    },
    async save() {
      const invoice = { ...this.model }

      invoice.date = this.$moment(invoice.date).format('YYYY-MM-DD')

      invoice.value = invoice.value.replace('R$ ', '')
      invoice.value = invoice.value.replace(',', '')

      if (!(await this.validation()))
        return this.$error('É necessário preencher todos os campos com *')

      console.log(invoice)

      try {
        invoice.id
          ? await service.updateInvoice(invoice)
          : await service.saveInvoice(invoice)
        await this.$success('Nota Fiscal')
        location.reload(true)
      } catch (error) {
        console.log(error)
        this.$error(error.response.data.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover a nota fiscal?')

        if (result.isConfirmed) {
          await service.deleteInvoice(id)

          await this.$delete('Nota Fiscal')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
